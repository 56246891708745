import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NoticeService {
  noticeList: { message: string, type: 'success' | 'error' | 'info' | 'warning', time: number, action: { hasAction: boolean, text: string, route: string }}[] = [];

  addNotice(message: string, type: 'success' | 'error' | 'info' | 'warning' = 'success', time = 3, action: { hasAction: boolean, text: string, route: string } = { hasAction: false, text: '', route: '' }) {
    this.noticeList = [
      ...this.noticeList,
      { message: message, type: type, time: time, action }
    ];
  }

  getNotices() {
    const newNotice = this.noticeList;
    this.noticeList = [];
    return newNotice;
  }
}
